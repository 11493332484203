import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Storage from "../../Firebase/Storage";
import uniqid from "uniqid";
import FireStore from "../../Firebase/Firestore";
import { useParams } from "react-router-dom";

function EditPost() {
  const { id } = useParams();
  const [image, setImage] = useState(null);
  const [secondaryMedia, setSecondaryMedia] = useState(null);
  const [imageBuffer, setImageBuffer] = useState(null);
  const [secondaryMediaBuffer, setSecondaryMediaBuffer] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState();
  const [message, setMessage] = useState({ message: null, severity: null });
  const [post, setPost] = useState(null);
  const [secondaryMediaType, setSecondaryMediaType] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      await firestore.getPost(id).then((val) => {
        if (val.code == 0) {
          const postData = { ...val.val.data(), id: val.val.id };
          setPost(postData);
          
          // Set initial image if exists
          if (postData.image) {
            setImage(postData.image);
          }

          // Set secondary media if exists
          if (postData.secondaryMedia) {
            setSecondaryMedia(postData.secondaryMedia);
            // Determine media type (image or video)
            setSecondaryMediaType(
              postData.secondaryMedia.match(/\.(jpeg|jpg|png)$/i) ? 'image' : 'video'
            );
          }

          setDataLoading(false);
        } else {
          setMessage({ message: val.val.message, severity: "error" });
          setOpen(true);
          setDataLoading(false);
        }
      });
    })();
  }, []);

  const db = new Storage();
  const firestore = new FireStore();

  const imageHandler = (e, isSecondary = false) => {
    let reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsArrayBuffer(file);
    
    reader.onload = () => {
      if (isSecondary) {
        setSecondaryMediaBuffer(reader.result);
        setSecondaryMedia(URL.createObjectURL(file));
        setSecondaryMediaType(file.type.startsWith('image/') ? 'image' : 'video');
      } else {
        setImageBuffer(reader.result);
        setImage(URL.createObjectURL(file));
      }
    };
    
    reader.onerror = () => {
      alert(reader.error);
    };
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = new FormData(e.target);
    let imagelink = post.image;
    let secondaryMedialink = post.secondaryMedialink || "";

    // Primary Image Handling
    if (imageBuffer != null) {
      const imageUpload = await db.getFileUrl(id, imageBuffer);
      if (imageUpload.code === 1) {
        await db.deleteFile(post.image);
        setMessage({ message: imageUpload.val.message, severity: "error" });
        setOpen(true);
        setLoading(false);
        return;
      }
      imagelink = imageUpload.val;
    }

    // Secondary Media Handling (Optional)
    if (secondaryMediaBuffer) {
      const secondaryUpload = await db.getFileUrl(`${id}-secondary`, secondaryMediaBuffer);
      if (secondaryUpload.code === 1) {
        await db.deleteFile(post.secondaryMedia);
        setMessage({ message: secondaryUpload.val.message, severity: "error" });
        setOpen(true);
        setLoading(false);
        return;
      }
      secondaryMedialink = secondaryUpload.val;
    }

    await firestore
      .updatePost(
        form.get("postTitle"), 
        imagelink, 
        form.get("postContent"), 
        id,
        secondaryMedialink,
        form.get("postSecondaryContent")
      )
      .then(async (val) => {
        if (val.code == 0) {
          setMessage({
            message: "Successfully updated post!",
            severity: "success",
          });
          setOpen(true);
          await new Promise((res) => setTimeout(res, 2000));
          setLoading(false);
          window.location.href = "/blog";
        } else {
          setMessage({
            message: `Failed to update post!: ${val.val}`,
            severity: "error",
          });
          setOpen(true);
          setLoading(false);
        }
      });
  };

  const videoRef = useRef(null);

  const renderSecondaryMedia = () => {
    if (!secondaryMedia) return null;

    if (secondaryMediaType === 'image') {
      return (
        <Box
          component="img"
          sx={{
            height: 400,
            width: "auto",
            maxWidth: "100%",
            objectFit: "contain"
          }}
          alt="Secondary media"
          src={secondaryMedia}
        />
      );
    } else {
      return (
        <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
            margin: "0 auto"
          }}
        >
          <video
            ref={videoRef}
            controls
            style={{
              width: "100%",
              maxHeight: "400px",
              objectFit: "contain"
            }}
            preload="metadata"
          >
            <source src={secondaryMedia} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      );
    }
  };

  return (
    <div className="min-h-screen">
      {dataLoading ? (
        <div className="flex justify-center place-items-center mt-20">
          <CircularProgress size={100} />
        </div>
      ) : (
        post && (
          <Card
            sx={{
              minWidth: 275,
              marginY: "20px",
              marginX: { md: "200px", xs: "20px" },
            }}
          >
            <Box
              component="form"
              onSubmit={submitHandler}
              noValidate={true}
              sx={{ mt: 3 }}
            >
              <CardContent>
                {/* Post Title */}
                <Grid item xs={12}>
                  <label
                    htmlFor="postTitle"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Post Title
                  </label>
                  <TextField
                    name="postTitle"
                    required
                    fullWidth
                    id="postTitle"
                    label="Post Title"
                    defaultValue={post.title}
                    autoFocus
                  />
                </Grid>

                {/* Primary Image Upload */}
                <Grid item xs={12}>
                  <label
                    htmlFor="dropzone-file"
                    className="block mb-2 mt-3 text-sm font-medium text-gray-900 dark:text-white w-full"
                  >
                    Cover Image
                  </label>
                  {image != null ? (
                    <div className="flex justify-center mb-5">
                      <Box
                        component="img"
                        sx={{
                          height: 400,
                          width: "auto",
                        }}
                        alt="Cover image"
                        src={image}
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">Click to upload</span>{" "}
                            or drag and drop image.
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            JPEG, PNG, or JPG
                          </p>
                        </div>
                      </label>
                    </div>
                  )}
                  <input
                    onChange={imageHandler}
                    id="dropzone-file"
                    type="file"
                    accept=".jpeg, .png, .jpg"
                    className="block my-5 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  />
                </Grid>

                {/* Secondary Media Upload Section */}
                <Grid item xs={12}>
                  <label
                    htmlFor="dropzone-secondary-file"
                    className="block mb-2 mt-3 text-sm font-medium text-gray-900 dark:text-white w-full"
                  >
                    Optional Secondary Media
                  </label>
                  {secondaryMedia != null ? (
                    <div className="flex justify-center mb-5">
                      {renderSecondaryMedia()}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="dropzone-secondary-file"
                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">Click to upload</span> optional secondary media
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            JPEG, PNG, JPG, MP4 (Optional)
                          </p>
                        </div>
                      </label>
                    </div>
                  )}
                  <input
                    onChange={(e) => imageHandler(e, true)}
                    id="dropzone-secondary-file"
                    type="file"
                    accept=".jpeg, .png, .jpg, .mp4"
                    className="block my-5 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  />
                </Grid>

                {/* Primary Post Content */}
                <Grid item xs={12}>
                  <label
                    htmlFor="postContent"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Post Content
                  </label>
                  <TextField
                    required
                    multiline
                    fullWidth
                    rows={15}
                    maxRows={30}
                    defaultValue={post.content}
                    id="postContent"
                    label="Post Content"
                    name="postContent"
                  />
                </Grid>

                {/* Optional Secondary Content */}
                <Grid item xs={12}>
                  <label
                    htmlFor="postSecondaryContent"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Optional Secondary Content
                  </label>
                  <TextField
                    multiline
                    fullWidth
                    rows={10}
                    maxRows={30}
                    defaultValue={post.secondaryContent || ''}
                    id="postSecondaryContent"
                    label="Optional Secondary Content"
                    name="postSecondaryContent"
                  />
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  disabled={loading}
                  variant="contained"
                  size="small"
                >
                  {loading ? "Updating Post..." : "Submit"}
                </Button>
              </CardActions>
            </Box>
          </Card>
        )
      )}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={message.severity}
          sx={{ width: "100%" }}
        >
          {message.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default EditPost;